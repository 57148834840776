.Header{
  height: 22vh;

  @include flex-column;
  justify-content: flex-end;

  nav{
    width: 100vw;
    display: flex;

    .btn-wrapper{
      height: 100%;
      width: 10rem;
      @include flex-row;

      color: $dark-pink;
      a{
        .icon-description{
          display: none;
          width: 100%;
        }
        &:hover{
          .icon-description{
            display: block;
            color: $dark-pink;
          }
        }
      }
    }
  }
}
.logo-header{
  color: $font-color;
  width: 10rem;
  height: 10rem;

  position: absolute;

  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

[class$="-icon"] {
  color: $dark-pink;
  transition: color .5s;
  height: 3rem;
  width: 3rem;
  &:hover{
    color: $dark-pink;
  }
} 

@media (min-width: 768px) and (max-width: 1024px){
  
  .Header{
    height: 15vh;
    nav{
      height: 100%;

      justify-content: flex-end;
      align-items: center;

      padding-top: 3rem;

      .btn-wrapper{
        a{
          .icon-description{
            display: none;
            width: 100%;
          }
          &:hover{
            .icon-description{
              display: block;
              color: $dark-pink;
            }
          }
        }
      }
    }
  }
  .logo-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    .logo-header{
      color: $dark-pink;
      width: 15rem;
      height: 15rem;
      transform: translateX(50%);
    }

  }
  

  [class$="-icon"] {
    color:$font-color;
    transition: color .5s;
    height: 5rem;
    width: 5rem;
    &:hover{
      color: $dark-pink;
    }
  } 

}

@media (min-width: 1025px){
  .logo-header{
    color: $dark-pink;
    width: 15rem;
    height: 15rem;
  }
  .Header{
    height: 15vh;
    nav{
      height: 100%;

      justify-content: flex-end;
      align-items: center;

      padding-top: 3rem;

      .btn-wrapper{
        a{
          .icon-description{
            display: none;
            width: 100%;
          }
          &:hover{
            .icon-description{
              display: block;
              color: $dark-pink;
            }
          }
        }
      }
    }
  }

  [class$="-icon"] {
    color:$font-color;
    transition: color .5s;
    height: 5rem;
    width: 5rem;
    &:hover{
      color: $dark-pink;
    }
  } 

}



