.Portfolio{
  min-height: 100vh;
  max-width: 100vw;
  @include flex-column;

  &__title{
    padding: 2rem;
  }
  &__description{
    padding: 2rem;
  }
  .Wrapper{
    @include flex-column;
    min-width: 65%;
    .projects-container{
      min-width: 100%;
    }
    .project-image{
      max-width: 48%;
      height: 30vh;
    
      border-radius: 10px;
      box-shadow: 0 1rem 2rem rgba($shadow, .10);
    
      text-align: center;
      margin: 1rem 1rem;
    
      cursor: pointer;
    
      transition: all .2s;
    
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left top;
    
      &:hover{
        box-shadow: 0 1rem 2rem rgba($shadow, .50);
        transform: translateY(-2px);
      }
      &:active{
        box-shadow: 0 .5rem 1rem rgba($shadow, .50);
        transform: translateY(0);
      }   
    }
  }
}

.logo{
  color: $dark-pink;
  width: 15rem;
  height: 15rem;

  position: absolute;

  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1025px){
  .Portfolio{
    position: relative;
  .projects-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 90%;
  }
  .Wrapper{
    width: 65%;
    position: relative;
  }
}
  .logo{
    left: 2rem;
    transform: translateX(0);
  }
  .project-image{
    width: 50%;    
  }
}


