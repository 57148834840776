.Footer{
  height: 8vh;
  width: 100vw;

  font-size: 1rem;

  position: absolute;
  bottom: 0;

  @include flex-row;
}

@media (min-width: 768px) and (max-width: 1024px){
 .Footer{
  font-size: 2rem;
}
}

@media (min-width: 1025px){
  .Footer{
    font-size: 1.5rem;
  }
}