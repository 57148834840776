@mixin flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}