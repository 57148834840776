//COLORS

$background: #282828;
$modal-background: #343a41;
$overlay-background: #22262a69;
$dark-pink: #C0392B;

$font-color: #f3dde3;

$shadow: #FFF;
$black: black;
