*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: 'Staatliches', cursive;
  color: $font-color;
}

body {
  height: 100vh;
  background: $background;
  background-repeat: no-repeat;
}


.Landing{
  height: 100vh;
  width: 100vw;

  position: relative;
}

.btn:link,
  :visited{
    text-decoration: none;
    color: $font-color;
}

