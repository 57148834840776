.Landing{

  &-content{
    text-align: center;

    width: 60%;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%,-50%);
  }

  &__title{
    h2{
      padding-bottom: 3rem;
    }
  }

  &-text{
    @include flex-column;

    .btn-portfolio{
  
      width: 25rem;
      @include flex-column;
  
      border: 2px solid $dark-pink;
      border-radius: 1rem;
  
      padding: 1rem;

      transition: all .5s;
      
      animation: moveInBottom 1.5s;

      .canada-icon{
        color: $dark-pink;
      }
  
      span{
        font-size: 2rem;
        margin-top: 1rem;
      }

      &:hover{
        transform: translateY(-5px);
        background-color: $dark-pink;

        .canada-icon{
          color: $black;
        }
        color: $black;
      }
    }

  }
}
