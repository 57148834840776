.project-modal{

  margin-top: 2rem;
  width:80%;
  min-height:70%;

  font-size: 5rem;

  position: relative;

  transition: all .5s;
  animation: popUp 1s;

  overflow: scroll;
  
    .close-icon{
    height: 3rem;
    width: 3rem;

    position: absolute;
    right: 2rem;
    top: 2rem;

    color: $dark-pink;

    &:hover{
      cursor: pointer;
      color: lighten($dark-pink, 20%);
    }
  }

  .modal-image{
    width: 100%;
    height: 30%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
    border-radius: 10px 0 0 10px;
  }

  .modal-content{
    border-radius: 0 0 10px 10px;
    background-color: $modal-background;

    width: 100%;
    min-height: 50%;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-technologies{
      display: flex;
      flex-wrap: wrap;
      span{
        background-color: $dark-pink;
        padding: .5rem 1rem;
        border-radius: 10rem;
        margin-right: 2rem;
        margin-top: 1rem;
      }
    }
    h3{
      margin: 2rem 0;
    }
    .btn-link-wrapper{
      display: flex;
      
        a{
          border: 2px solid $dark-pink;
          padding: .5rem;
          border-radius: 1rem;
          width: 40%;
    
          margin: 2rem 2rem;

          transition: all .5s; 
          
          display: flex;
          justify-content: center;
          
          span{
            padding: .5rem;
          }
          .eye-icon{
            color: $dark-pink;
          }
          .code-icon{
            color: $dark-pink;
          }
          &:hover{
            background-color: $dark-pink;
            .eye-icon{
              color: $black;
            }
            .code-icon{
              color: $black;
            }
            color: $black;
          }
        }
    }

  }
}

.project-modal-overlay{
  position: fixed;
  top: 0;
  right: 0;
  inset: 0px;
  background-color: $overlay-background;

  display: flex;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 1024px){
  .project-modal{
    margin-top: 3rem;

    .modal-content{
      justify-content: center;

      h1{
        margin-bottom: 3rem;
      }
      .modal-technologies{
        span{
          margin-bottom:  1.5rem;
        }
      }
      h3{
        margin: 2rem 0;
      }
      p{
        margin: 2rem 0;
      }

      .btn-link-wrapper{
        display: flex;
        
          a{
            border: 2px solid $dark-pink;
            padding: .5rem;
            border-radius: 1rem;
            width: 30%;
      
            margin: 2rem 2rem;
  
            transition: all .5s; 
            
            @include flex-row;
            
            span{
              padding: 1rem;
            }
            .eye-icon{
              color: $dark-pink;
            }
            .code-icon{
              color: $dark-pink;
            }
            &:hover{
              background-color: $dark-pink;
              .eye-icon{
                color: $black;
              }
              .code-icon{
                color: $black;
              }
              color: $black;
            }
          }
      }
  
    }
  }
}

@media (min-width: 1025px){
  .project-modal{
    width:55%;
    height: 55%;

    overflow: hidden;
  
    display: flex;
  
    .modal-image{
      width: 60%;
      height: 100%;
      img{
        border-radius: 10px 0 0 10px;
      }
    }
    
    .modal-content{
      width: 40%;
      height: 100%;
      justify-content: center;
      border-radius: 0 10px 10px 0;

      h1{
        margin-bottom: 3rem;
      }

      .modal-technologies{
        span{
          margin-bottom:  1.5rem;
        }
      }
      h3{
        margin: 2rem 0;
      }
      p{
        margin: 2rem 0;
      }

      .btn-link-wrapper{
        a{
          @include flex-row;
          .eye-icon{
            width: 3.5rem;
            height: 3.5rem;
          }
          .code-icon{
            width: 3.5rem;
            height: 3.5rem;
          }
        }
      }
    }
  }

  .project-modal-overlay{
    align-items: center ;
  }
}