.Landing{
  text-transform: uppercase;
  letter-spacing: .4rem;

  h1{
    font-size: 5rem;

  }
  h2{
    font-size: 2rem;
    font-weight: 100;
  }

  .btn-portfolio{
    color: $dark-pink;
  }
}
.Header {
  nav{
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 500;
    a{
      text-decoration: none;
      color: $dark-pink;
      .icon-description{
        font-size: 1.3rem;
      }
    }
  }
}

.Portfolio{
  &__title{
    font-size: 3.5rem;
    color: $dark-pink;

  }
  &__description{
    font-size: 1.5rem;
    color: #94a4b4;
    font-style: italic;
    font-weight: 100;
    font-family: Inconsolata,sans-serif
  }
}

.project-modal{
  color: $dark-pink;
  .modal-content{
    h1{
      font-size: 3rem;
      letter-spacing: .5rem;
      font-weight: 100;
    }
    .modal-technologies{
      span{
        font-size: 1.5rem;
        color: $font-color;
      }
    }
    h3{
      font-size: 2rem;
      letter-spacing: .3rem;
      font-weight: 100;
    }
    p{
      font-size: 1.5rem;
      color: $font-color;
      font-weight: 100;
      font-family: Inconsolata,sans-serif;
      letter-spacing: .2rem;
      text-align: justify;
    }
    a{
      font-size: 1.5rem;
      text-decoration: none;
      color: $dark-pink;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .Landing{

    h1{
      font-size: 10.5rem;
  
    }
    h2{
      font-size: 3rem;
    }
  }
    .Header {
      nav{
        a{
          color: $font-color;
        }
      }
    }
  
    .project-modal{
      .modal-content{
        h1{
          font-size: 5rem;
        }
        .modal-technologies{
          span{
            font-size:  2.5rem;
          }
        }
        h3{
          font-size: 3rem;
        }
        p{
          font-size: 2rem;
        }
        .project-link{
          span{
            font-size: 2.5rem;
          }
        }      
      }
    }
}

@media (min-width: 1025px){
  .Landing{

  h1{
    font-size: 10.5rem;

  }
  h2{
    font-size: 3rem;
  }
}
  .Header {
    nav{
      a{
        color: $font-color;
      }
    }
  }

  .project-modal{
    color: $dark-pink;
  }
}

