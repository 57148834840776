@keyframes moveInBottom {
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0;
    transform: translateY(30px);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popUp{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}